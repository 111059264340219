import React, { memo, useCallback, useEffect, useMemo } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType, TargetHandle } from "../../../models/nodeType";
import BaseNode from "../../../features/reactflow/nodes/base/BaseNode";
import { Controller, useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";

interface FormData {
  addInputCount: number;
  subInputCount: number;
}

const AdditiveOperatorNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = {}, targetHandles = [] },
  } = props;

  const formData = nodeData as FormData | undefined;
  const addInputCount = formData?.addInputCount ?? 2;
  const subInputCount = formData?.subInputCount ?? 0;

  const { handleSubmit, control, watch } = useForm<FormData>({
    defaultValues: useMemo(
      () => ({
        addInputCount,
        subInputCount,
      }),
      [addInputCount, subInputCount]
    ),
    mode: "onChange",
  });

  const reactFlow = useReactFlow();
  const { updateNodeTargetHandles } = useUpdateNodeHandles(nodeId);

  const handleUpdate = useCallback(
    ({ addInputCount, subInputCount }: FormData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.addInputCount = addInputCount;
        nodeData.subInputCount = subInputCount;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow, nodeId]
  );

  const watchedAddInputCount = watch("addInputCount");
  const watchedSubInputCount = watch("subInputCount");

  useEffect(() => {
    const currentAddInputCount = targetHandles.filter(({ handleName }) => handleName === "add").length;

    if (currentAddInputCount === watchedAddInputCount) {
      return;
    }

    const targetHandlesExcludingAdd = targetHandles.filter(({ handleName }) => handleName !== "add");

    const targetHandlesIncludingAdd = targetHandles.filter(({ handleName }) => handleName === "add");

    if (currentAddInputCount < watchedAddInputCount) {
      const handlesToInsert = watchedAddInputCount - currentAddInputCount;

      targetHandlesIncludingAdd.push(
        ...[...Array(handlesToInsert)].map(() => {
          const targetHandle: TargetHandle = {
            label: "ADD",
            handleName: "add",
            handleType: "target",
            handleCategory: "data",
          };

          return targetHandle;
        })
      );
    } else {
      const handlesToRemove = currentAddInputCount - watchedAddInputCount;

      targetHandlesIncludingAdd.splice(-handlesToRemove);
    }

    updateNodeTargetHandles([...targetHandlesIncludingAdd, ...targetHandlesExcludingAdd]);
  }, [targetHandles, watchedAddInputCount]);

  useEffect(() => {
    const currentSubInputCount = targetHandles.filter(({ handleName }) => handleName === "sub").length;

    if (currentSubInputCount === watchedSubInputCount) {
      return;
    }

    const targetHandlesExcludingSub = targetHandles.filter(({ handleName }) => handleName !== "sub");

    const targetHandlesIncludingSub = targetHandles.filter(({ handleName }) => handleName === "sub");

    if (currentSubInputCount < watchedSubInputCount) {
      const handlesToInsert = watchedSubInputCount - currentSubInputCount;

      targetHandlesIncludingSub.push(
        ...[...Array(handlesToInsert)].map(() => {
          const targetHandle: TargetHandle = {
            label: "SUB",
            handleName: "sub",
            handleType: "target",
            handleCategory: "data",
          };

          return targetHandle;
        })
      );
    } else {
      const handlesToRemove = currentSubInputCount - watchedSubInputCount;

      targetHandlesIncludingSub.splice(-handlesToRemove);
    }

    updateNodeTargetHandles([...targetHandlesExcludingSub, ...targetHandlesIncludingSub]);
  }, [targetHandles, watchedSubInputCount]);

  return (
    <BaseNode {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Addition Port Count
            </Text>
          </FormLabel>
          <Controller
            name={"addInputCount"}
            control={control}
            render={({ field: { ref, value, onChange, onBlur, name } }) => (
              <NumberInput
                value={value}
                defaultValue={2}
                name={name}
                step={1}
                min={0}
                max={16}
                ref={ref}
                onChange={(value) => onChange(Number(value))}
                onBlur={onBlur}
              >
                <NumberInputField color={color} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Subtraction Port Count
            </Text>
          </FormLabel>
          <Controller
            name={"subInputCount"}
            control={control}
            render={({ field: { ref, value, onChange, onBlur, name } }) => (
              <NumberInput
                value={value}
                defaultValue={2}
                name={name}
                step={1}
                min={0}
                max={16}
                ref={ref}
                onChange={(value) => onChange(Number(value))}
                onBlur={onBlur}
              >
                <NumberInputField color={color} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          />
        </FormControl>
      </form>
    </BaseNode>
  );
};

export default memo(AdditiveOperatorNode);
