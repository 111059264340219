import { useCallback } from "react";
import { useReactFlow } from "reactflow";
import { NodeType } from "../models/nodeType";
import { EdgeType } from "../models/edgeType";

const useUpdateNodeData = <T>(nodeId: string | null) => {
  const { setNodes } = useReactFlow<NodeType, EdgeType>();

  const updateNodeData = useCallback(
    (nodeData: Partial<T>) => {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === nodeId) {
            node.data = {
              ...node.data,
              nodeData: {
                ...node.data.nodeData,
                ...nodeData,
              },
            };
          }

          return node;
        })
      );
    },
    [setNodes, nodeId]
  );

  return {
    updateNodeData,
  };
};

export { useUpdateNodeData };
