import { memo, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import BaseNode from "../../../features/reactflow/nodes/base/BaseNode";
import { useForm } from "react-hook-form";
import { Stack } from "@chakra-ui/react";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { LootTableRewardNodeData } from "@worldwidewebb/quest-shared/dist/lootTable";
import { Form } from "react-router-dom";
import SelectLootTable from "../../base/SelectLootTable";

function LootTableRewardNode(props: NodeProps<NodeType<LootTableRewardNodeData>>) {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const lootTableId = nodeData?.lootTableId ?? "";

  const { getValues, setValue, handleSubmit } = useForm<LootTableRewardNodeData>({
    defaultValues: useMemo(
      () => ({
        lootTableId,
      }),
      [lootTableId]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData<LootTableRewardNodeData>(nodeId);

  return (
    <BaseNode {...props}>
      <Form onSubmit={handleSubmit(updateNodeData)} onBlur={handleSubmit(updateNodeData)}>
        <Stack p={2}>
          <SelectLootTable
            color={color}
            value={getValues("lootTableId")}
            setValue={(value) => setValue("lootTableId", value)}
          />
        </Stack>
      </Form>
    </BaseNode>
  );
}

export default memo(LootTableRewardNode);
