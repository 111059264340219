import { Box } from "@chakra-ui/react";
import { memo, useMemo } from "react";
import { NodeProps } from "reactflow";
import QuestContainer from "../../components/quests/QuestContainer";
import useQuest from "../../hooks/quests/useQuest";
import { NodeType } from "../../models/nodeType";
import BaseNode from "../reactflow/nodes/base/BaseNode";
import QuestNodeBanner from "./QuestNodeBanner";
import QuestNodeHeader from "./QuestNodeHeader";
import QuestNodeOverview from "./QuestNodeOverview";

function QuestNode(nodeProps: NodeProps<NodeType>) {
  const {
    id: questId,
    data: { color, version: questVersion },
  } = nodeProps;

  const { isLoading, quest } = useQuest(questId, questVersion);

  const header = useMemo(
    () => quest && <QuestNodeHeader color={color} quest={quest} isLoading={isLoading} />,
    [color, quest, isLoading]
  );

  return (
    <>
      <QuestNodeBanner quest={quest} />

      <Box position={"relative"}>
        <QuestContainer mx={1} mb={1} color={color} questId={questId} />

        <BaseNode header={header} {...nodeProps} data={{ ...nodeProps.data, label: quest?.name }}>
          <QuestNodeOverview color={color} quest={quest} />
        </BaseNode>
      </Box>
    </>
  );
}

export default memo(QuestNode);
