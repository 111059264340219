import { FlexProps } from "@chakra-ui/react";
import { Position } from "reactflow";

type FlexDirection = FlexProps["flexDirection"];

export function getBaseHandleDirection(position: Position): FlexDirection {
  switch (position) {
    case Position.Left: {
      return "row";
    }
    case Position.Right: {
      return "row-reverse";
    }
    default: {
      return undefined;
    }
  }
}
