import React, { memo, useRef } from "react";
import CodeMirror, { EditorView, ReactCodeMirrorProps, ReactCodeMirrorRef } from "@uiw/react-codemirror";
import { Box, ChakraProps, Stack } from "@chakra-ui/react";
import CommandToolbar from "./components/CommandToolbar";
import { getCommands } from "./commands";
import ContextMenu from "../base/ContextMenu";
import CommandMenuList from "./components/CommandMenuList";

interface EditorProps extends ReactCodeMirrorProps, Pick<ChakraProps, "borderColor" | "borderRadius" | "borderWidth"> {
  value?: string;
  onChange?: (value: string) => void;
}

const Editor: React.FC<EditorProps> = ({
  value,
  onChange,
  borderColor,
  borderRadius,
  borderWidth,
  height = "10rem",
}) => {
  const editorRef = useRef<ReactCodeMirrorRef>(null);
  const commands = getCommands();

  return (
    <Stack p={1} spacing={0} borderColor={borderColor} borderRadius={borderRadius} borderWidth={borderWidth}>
      <CommandToolbar editorRef={editorRef} commands={commands} />

      <ContextMenu renderMenu={() => <CommandMenuList editorRef={editorRef} commands={commands} zIndex={2000} />}>
        {(ref) => (
          <Box ref={ref}>
            <CodeMirror
              ref={editorRef}
              value={value}
              onChange={onChange}
              height={height}
              theme={"dark"}
              basicSetup={{ lineNumbers: false, highlightActiveLine: false, highlightActiveLineGutter: false }}
              extensions={[EditorView.lineWrapping]}
            />
          </Box>
        )}
      </ContextMenu>
    </Stack>
  );
};

export default memo(Editor);
