import { FormControl, FormLabel, Textarea, TextareaProps } from "@chakra-ui/react";
import { ForwardedRef, forwardRef, memo } from "react";
import { getScrollbarStyle } from "../styles/scrollbar";

interface FormTextAreaProps extends TextareaProps {
  disabled?: boolean;
}

const FormTextArea = forwardRef(({ children, ...props }: FormTextAreaProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
  const { color, colorScheme, borderColor = color, borderRadius = 0, borderWidth = 2, minH = 32 } = props;
  const sx = { ...props.sx, ...getScrollbarStyle(color?.toString()) };

  return (
    <FormControl>
      <FormLabel>{children}</FormLabel>

      <Textarea
        ref={ref}
        {...props}
        sx={sx}
        cursor={"auto"}
        overflowY={"auto"}
        color={color}
        colorScheme={colorScheme}
        borderColor={borderColor}
        borderRadius={borderRadius}
        borderWidth={borderWidth}
        minH={minH}
      />
    </FormControl>
  );
});

export default memo(FormTextArea);
