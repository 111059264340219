import { useToken } from "@chakra-ui/system";
import { memo } from "react";
import { Position } from "reactflow";
import BaseNodeBaseHandle, { BaseNodeBaseHandleProps } from "./BaseNodeBaseHandle";

type BaseNodeTargetHandleProps = Omit<BaseNodeBaseHandleProps, "type" | "position">;

function BaseNodeTargetHandle(props: BaseNodeTargetHandleProps) {
  const { color: token = "white" } = props;

  const [color] = useToken("colors", [token]);

  return <BaseNodeBaseHandle {...props} color={color} type={"target"} position={Position.Left} />;
}

export default memo(BaseNodeTargetHandle);
