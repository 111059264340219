import React, { memo, useCallback } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import BaseNode from "../../../features/reactflow/nodes/base/BaseNode";
import { useForm } from "react-hook-form";
import { Box, Checkbox, FormControl, FormLabel, NumberInput, NumberInputField, Text } from "@chakra-ui/react";

interface FormData {
  value: boolean;
}

const NumberNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = {} },
  } = props;

  const formData = nodeData as FormData | undefined;
  const value = formData?.value;

  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      value,
    },
    mode: "onBlur",
  });

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(
    ({ value }: FormData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.value = value;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow]
  );

  return (
    <BaseNode {...props}>
      <Box p={2} borderWidth={1}>
        <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
          <FormControl>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Value
              </Text>
            </FormLabel>
            <NumberInput isRequired>
              <NumberInputField id={"value"} {...register("value", { valueAsNumber: true })} color={color} />
            </NumberInput>
          </FormControl>
        </form>
      </Box>
    </BaseNode>
  );
};

export default memo(NumberNode);
