import { Flex, Tag, Text } from "@chakra-ui/react";
import { memo, useCallback } from "react";
import { Handle, HandleProps, useNodeId } from "reactflow";
import { useConnectionProvider } from "../../../../context/ConnectionContext";
import { useReactFlowAutoConnectProvider } from "../../../../context/reactflow/ReactFlowAutoConnectProvider";
import { useBaseHandleStyles } from "./utils/useBaseHandleStyles";

export interface BaseNodeBaseHandleProps extends HandleProps {
  handleId: string;
  handleName: string;
  color?: string;
  label?: string;
  isSelected?: boolean;
}

function BaseNodeBaseHandle(props: BaseNodeBaseHandleProps) {
  const { handleId, handleName, color, label, type } = props;

  const { selectHandle } = useConnectionProvider();

  const nodeId = useNodeId();

  const handleSelectHandle = useCallback(() => {
    selectHandle(nodeId, handleId, type);
  }, [selectHandle, nodeId, handleId, type]);

  const { autoConnect } = useReactFlowAutoConnectProvider();

  const handleAutoConnect = useCallback(() => {
    if (autoConnect == null) {
      return;
    }

    autoConnect(handleId, handleName);
  }, [autoConnect, handleId, handleName]);

  const { direction, style } = useBaseHandleStyles(props);

  return (
    <Flex px={2} py={1} direction={direction}>
      <Handle
        id={handleId}
        color={color}
        onClick={handleSelectHandle}
        onDoubleClick={handleAutoConnect}
        style={style}
        {...props}
      />

      <Tag color={color} onClick={handleSelectHandle} onDoubleClick={handleAutoConnect}>
        <Text color={color} casing={"uppercase"}>
          {label}
        </Text>
      </Tag>
    </Flex>
  );
}

export default memo(BaseNodeBaseHandle);
