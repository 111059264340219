import { Box, BoxProps, Stack, Text } from "@chakra-ui/react";
import { NodeData } from "@worldwidewebb/quest-shared/dist/editor/nodeType";
import { memo, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-router-dom";
import { useNodeId } from "reactflow";
import { useUpdateNodeData } from "../../../../hooks/useUpdateNodeData";
import FormCheckbox from "../../../../ui/form/FormCheckbox";
import FormTextArea from "../../../../ui/form/FormTextArea";

export interface BaseNodeConfigTemplateProps extends BoxProps {
  nodeData: NodeData;
}

function BaseNodeConfigTemplate({ nodeData, ...props }: BaseNodeConfigTemplateProps) {
  const { color } = props;

  const { register, watch, handleSubmit } = useForm<NodeData>({
    defaultValues: useMemo(() => nodeData, [nodeData]),
    mode: "onChange",
  });

  const isTemplate = watch("isTemplate");

  const nodeId = useNodeId();

  const { updateNodeData } = useUpdateNodeData(nodeId);

  return (
    <Box {...props}>
      <Form
        onSubmit={handleSubmit(updateNodeData)}
        onBlur={handleSubmit(updateNodeData)}
        onChange={handleSubmit(updateNodeData)}
      >
        <Stack className={"nodrag"}>
          <FormCheckbox color={color} {...register("isTemplate")}>
            <Text color={color} casing={"uppercase"}>
              Is Template Node
            </Text>
          </FormCheckbox>

          {isTemplate && (
            <FormTextArea color={color} {...register("templateData.description")}>
              <Text color={color} casing={"uppercase"}>
                Description
              </Text>
            </FormTextArea>
          )}
        </Stack>
      </Form>
    </Box>
  );
}

export default memo(BaseNodeConfigTemplate);
