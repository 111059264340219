import React, { memo, useCallback } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { Box } from "@chakra-ui/react";
import SelectCraftingStation from "../../base/SelectCraftingStation";
import BaseNode from "../../../features/reactflow/nodes/base/BaseNode";

interface FormData {
  craftingStationId: string;
}

const CraftingStationNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = {} },
  } = props;

  const formData = nodeData as FormData | undefined;
  const craftingStationId = formData?.craftingStationId;

  const { getValues, setValue, handleSubmit } = useForm<FormData>({
    defaultValues: {
      craftingStationId,
    },
    mode: "onBlur",
  });

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(
    ({ craftingStationId }: FormData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.craftingStationId = craftingStationId;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow]
  );

  return (
    <BaseNode {...props}>
      <Box p={2}>
        <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
          <SelectCraftingStation
            value={getValues("craftingStationId")}
            setValue={(value) => setValue("craftingStationId", value)}
            color={color}
          />
        </form>
      </Box>
    </BaseNode>
  );
};

export default memo(CraftingStationNode);
