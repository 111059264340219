import React, { DragEvent } from "react";
import BaseNodeHeader from "../features/reactflow/nodes/base/BaseNodeHeader";
import { onNodeDragStart } from "../utils/dragEvent";

interface NodeTypeListItemProps {
  color?: string;
  nodeName: string;
}

export const NodeTypeListItem: React.FC<NodeTypeListItemProps> = ({ color, nodeName }) => {
  return (
    <BaseNodeHeader
      color={color}
      nodeName={nodeName}
      draggable
      onDragStart={(event: DragEvent) => onNodeDragStart(event, nodeName)}
      sx={{ cursor: "pointer" }}
    />
  );
};
