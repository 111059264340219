import { Card, CardBody, ChakraProps, Divider, SimpleGrid, Stack } from "@chakra-ui/react";
import { NodeData, NodeType } from "@worldwidewebb/quest-shared/dist/editor/nodeType";
import { memo, PropsWithChildren, ReactNode } from "react";
import { NodeProps } from "reactflow";
import { getHandleCategoryColor } from "../../../../models/nodeType";
import BaseNodeConfigTemplate from "./BaseNodeConfigTemplate";
import BaseNodeContainer from "./BaseNodeContainer";
import BaseNodeFooter from "./BaseNodeFooter";
import BaseNodeHeader from "./BaseNodeHeader";
import BaseNodeSourceHandle from "./BaseNodeSourceHandle";
import BaseNodeTargetHandle from "./BaseNodeTargetHandle";

interface BaseNodeProps extends NodeProps<NodeType<NodeData>>, Omit<ChakraProps, "zIndex">, PropsWithChildren {
  header?: ReactNode;
  headerButtons?: ReactNode;
}

function BaseNode(props: BaseNodeProps) {
  const {
    bg = "theme.dark.background",
    data: { nodeName, nodeData, sourceHandles = [], targetHandles = [], color, isReady, isReadyForTemplating },
    header,
    headerButtons,
    isConnectable,
    selected,
    children,
  } = props;

  return (
    <BaseNodeContainer>
      <Card bg={bg} borderRadius={0}>
        <BaseNodeHeader color={selected ? "white" : color} nodeName={nodeName}>
          {headerButtons}
        </BaseNodeHeader>

        {header && <CardBody>{header}</CardBody>}

        <SimpleGrid columns={2} py={1}>
          <Stack>
            {targetHandles.map(({ handleId = "", handleCategory, handleName, label }) => (
              <BaseNodeTargetHandle
                key={handleId}
                handleId={handleId}
                handleName={handleName}
                color={getHandleCategoryColor(handleCategory, isReady)}
                label={label}
                isSelected={selected}
                isConnectable={isConnectable}
              />
            ))}
          </Stack>

          <Stack>
            {sourceHandles.map(({ handleId = "", handleCategory, handleName, label }) => (
              <BaseNodeSourceHandle
                key={handleId}
                handleId={handleId}
                handleName={handleName}
                color={getHandleCategoryColor(handleCategory, isReady)}
                label={label}
                isSelected={selected}
                isConnectable={isConnectable}
              />
            ))}
          </Stack>
        </SimpleGrid>

        {children && <CardBody>{children}</CardBody>}

        {isReadyForTemplating && (
          <>
            <Divider />

            <CardBody>
              <BaseNodeConfigTemplate color={color} nodeData={nodeData} />
            </CardBody>
          </>
        )}

        <BaseNodeFooter color={selected ? "white" : color} />
      </Card>
    </BaseNodeContainer>
  );
}

export default memo(BaseNode);
