import { Position } from "reactflow";
import { CSSProperties } from "react";
import { BaseNodeBaseHandleProps } from "../BaseNodeBaseHandle";
import useShowConnectable from "./useShowConnectable";

export function getBaseHandleMargins(position: Position) {
  let margins: Partial<Record<keyof Pick<CSSProperties, "marginLeft" | "marginRight">, string>> = {};

  switch (position) {
    case Position.Left: {
      margins = { marginLeft: "-2.5rem", marginRight: "1.5rem" };
      break;
    }
    case Position.Right: {
      margins = { marginRight: "-2.5rem", marginLeft: "1.5rem" };
      break;
    }
  }

  return margins;
}

export function useBaseHandleStyle(props: BaseNodeBaseHandleProps): CSSProperties {
  const { handleId, position, isSelected, color } = props;

  const showConnectable = useShowConnectable(handleId);

  const borderColor = isSelected || showConnectable ? "white" : color;

  return {
    ...getBaseHandleMargins(position),
    width: "1.5rem",
    height: "1.5rem",
    backgroundColor: "transparent",
    borderColor,
    borderWidth: 6,
    top: "unset",
    right: "unset",
    bottom: "unset",
    left: "unset",
    transform: "none",
  };
}
