import { memo, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import BaseNode from "../../../features/reactflow/nodes/base/BaseNode";
import { useForm } from "react-hook-form";
import { NumberInput, NumberInputField, Stack } from "@chakra-ui/react";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { EnergyRewardNodeData } from "@worldwidewebb/quest-shared/dist/energy";
import SelectEnergy from "../../base/SelectEnergy";
import { Form } from "react-router-dom";

function EnergyRewardNode(props: NodeProps<NodeType<EnergyRewardNodeData>>) {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const energyId = nodeData?.energyId ?? "";
  const change = nodeData?.change ?? 0;

  const { register, getValues, setValue, handleSubmit } = useForm<EnergyRewardNodeData>({
    defaultValues: useMemo(
      () => ({
        energyId,
        change,
      }),
      [energyId, change]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData<EnergyRewardNodeData>(nodeId);

  return (
    <BaseNode {...props}>
      <Form onSubmit={handleSubmit(updateNodeData)} onBlur={handleSubmit(updateNodeData)}>
        <Stack p={2}>
          <SelectEnergy color={color} value={getValues("energyId")} setValue={(value) => setValue("energyId", value)} />

          <NumberInput defaultValue={0}>
            <NumberInputField
              color={color}
              borderColor={color}
              borderRadius={0}
              borderWidth={2}
              {...register("change", { valueAsNumber: true })}
            />
          </NumberInput>
        </Stack>
      </Form>
    </BaseNode>
  );
}

export default memo(EnergyRewardNode);
