import React, { memo, useCallback, useMemo } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { FormControl, FormLabel, Select, Text } from "@chakra-ui/react";
import BaseNode from "../../../features/reactflow/nodes/base/BaseNode";

type RelationalOperator = "item_names_and_quantities_are_equal" | "item_names_are_equal";

const relationalOperators: Record<RelationalOperator, string> = {
  item_names_and_quantities_are_equal: "Item Names and Quantities are Equal",
  item_names_are_equal: "Item Names are Equal",
};

interface FormData {
  operator: RelationalOperator;
}

const CompareItemsNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = {} },
  } = props;

  const formData = nodeData as FormData | undefined;
  const operator = formData?.operator ?? "item_names_and_quantities_are_equal";

  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: useMemo(
      () => ({
        operator,
      }),
      [operator]
    ),
    mode: "onChange",
  });

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(
    ({ operator }: FormData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.operator = operator;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow, nodeId]
  );

  return (
    <BaseNode {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Relational Operator
            </Text>
          </FormLabel>
          <Select id={"operator"} {...register("operator")}>
            {relationalOperators &&
              Object.entries(relationalOperators).map(([operator, label]) => (
                <option key={operator} value={operator}>
                  {label}
                </option>
              ))}
          </Select>
        </FormControl>
      </form>
    </BaseNode>
  );
};

export default memo(CompareItemsNode);
