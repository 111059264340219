import { Box } from "@chakra-ui/react";
import { memo, PropsWithChildren, useCallback } from "react";
import { useNodeId, useReactFlow } from "reactflow";
import useQuestPointerStatus from "../../../../hooks/quests/useQuestPointerStatus";
import getQuestPointerStatusColor from "../../../../utils/getQuestPointerStatusColor";

function BaseNodeContainer({ children }: PropsWithChildren) {
  const nodeId = useNodeId();

  const { questPointerStatus } = useQuestPointerStatus(nodeId);

  const borderColor = getQuestPointerStatusColor(questPointerStatus);
  const borderWidth = borderColor == null ? 0 : 4;
  const minW = "24rem";

  const { setNodes } = useReactFlow();

  const handleDeselectNode = useCallback(
    () =>
      setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node != null) {
          node.selected = false;
        }

        return nodes;
      }),
    [setNodes, nodeId]
  );

  return (
    <Box borderColor={borderColor} borderWidth={borderWidth} minW={minW} onKeyDown={handleDeselectNode}>
      {children}
    </Box>
  );
}

export default memo(BaseNodeContainer);
