import { BaseNodeBaseHandleProps } from "../BaseNodeBaseHandle";
import { getBaseHandleDirection } from "./getBaseHandleDirection";
import { useBaseHandleStyle } from "./useBaseHandleStyle";

export function useBaseHandleStyles(props: BaseNodeBaseHandleProps) {
  const { position } = props;

  const style = useBaseHandleStyle(props);

  return {
    direction: getBaseHandleDirection(position),
    style,
  };
}
