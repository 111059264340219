import { CardHeader, CardHeaderProps, Flex, HStack, Text } from "@chakra-ui/react";
import { memo } from "react";
import { useNodeTypesProvider } from "../../../../context/reactflow/NodeTypesProvider";
import Tooltip from "../../../../ui/base/chakra/Tooltip";
import BaseNodeCopyNodeIdButton from "./BaseNodeCopyNodeIdButton";
import ExperimentalNodeMarker from "./ExperimentalNodeMarker";

export interface BaseNodeHeaderProps extends CardHeaderProps {
  nodeName: string;
}

function BaseNodeHeader({ nodeName, children, ...props }: BaseNodeHeaderProps) {
  const { getNodeType } = useNodeTypesProvider();

  const nodeType = getNodeType(nodeName);

  if (nodeType == null) {
    return null;
  }

  const { color } = props;
  const { label, nodeDescription } = nodeType;

  return (
    <CardHeader borderBottomWidth={1} borderTopWidth={8} borderTopColor={color} position={"relative"} {...props}>
      <ExperimentalNodeMarker nodeName={nodeName} />

      <Flex alignItems={"center"} justifyContent={"space-between"} flexGrow={1} gap={8}>
        <Tooltip color={color} label={nodeDescription}>
          <Text color={color} casing={"uppercase"} fontWeight={700}>
            {label}
          </Text>
        </Tooltip>

        <HStack className={"nodrag"}>
          <BaseNodeCopyNodeIdButton color={color} />

          {children}
        </HStack>
      </Flex>
    </CardHeader>
  );
}

export default memo(BaseNodeHeader);
