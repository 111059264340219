import { ChakraProps, Icon, IconButton, useToast } from "@chakra-ui/react";
import { memo, useCallback } from "react";
import { AiOutlineCopy } from "react-icons/ai";
import { useNodeId } from "reactflow";
import Tooltip from "../../../../ui/base/chakra/Tooltip";

function BaseNodeCopyNodeIdButton({ color }: ChakraProps) {
  const toast = useToast();

  const nodeId = useNodeId();

  const handleCopyNodeId = useCallback(() => {
    if (nodeId == null) {
      return;
    }

    navigator.clipboard.writeText(nodeId).then(() => toast({ title: `Copied ID ${nodeId} to clipboard` }));
  }, [toast, nodeId]);

  if (nodeId == null) {
    return null;
  }

  return (
    <Tooltip label={"Copy ID"} color={color}>
      <IconButton
        aria-label={"Copy ID"}
        isRound={true}
        variant={"outline"}
        icon={<Icon as={AiOutlineCopy} position={"absolute"} />}
        borderColor={color}
        borderWidth={2}
        color={color}
        onClick={handleCopyNodeId}
      />
    </Tooltip>
  );
}

export default memo(BaseNodeCopyNodeIdButton);
