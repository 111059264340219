import { useToken } from "@chakra-ui/system";
import { memo } from "react";
import { Position } from "reactflow";
import BaseNodeBaseHandle, { BaseNodeBaseHandleProps } from "./BaseNodeBaseHandle";

type BaseNodeSourceHandleProps = Omit<BaseNodeBaseHandleProps, "type" | "position">;

function BaseNodeSourceHandle(props: BaseNodeSourceHandleProps) {
  const { color: token = "white" } = props;

  const [color] = useToken("colors", [token]);

  return <BaseNodeBaseHandle {...props} color={color} type={"source"} position={Position.Right} />;
}

export default memo(BaseNodeSourceHandle);
