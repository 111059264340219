import { createContext, PropsWithChildren, useCallback, useContext } from "react";
import { Edge, Node, useStoreApi } from "reactflow";
import { EdgeType } from "../../models/edgeType";
import { NodeType } from "../../models/nodeType";
import { useConnectionProvider } from "../ConnectionContext";

type AutoConnect = (targetNodeHandleId: string, targetNodeHandleName: string) => void;

interface ContextType {
  autoConnect?: AutoConnect;
}

const Context = createContext<ContextType | null>(null);

export interface ReactFlowAutoConnectConfig {
  sourceNodeName: string;
  sourceNodeHandleName: string;
}

interface ReactFlowAutoConnectProviderProps extends PropsWithChildren {
  nodeId: string;
  autoConnectConfigs?: ReactFlowAutoConnectConfig[];
}

export function ReactFlowAutoConnectProvider({
  nodeId: targetNodeId,
  autoConnectConfigs = [],
  children,
}: ReactFlowAutoConnectProviderProps) {
  const store = useStoreApi();

  const { onConnect } = useConnectionProvider();

  const autoConnect = useCallback(
    (targetNodeHandleId: string, targetNodeHandleName: string) => {
      autoConnectConfigs.forEach(({ sourceNodeName, sourceNodeHandleName }) => {
        if (sourceNodeHandleName !== targetNodeHandleName) {
          return;
        }

        const edges: Edge<EdgeType>[] = store.getState().edges;
        const nodes: Node<NodeType>[] = store.getState().getNodes();

        if (edges.some(({ targetHandle }) => targetHandle === targetNodeHandleId)) {
          return;
        }

        const sourceNode = nodes.find(({ data: { nodeName } }) => nodeName === sourceNodeName);

        if (sourceNode == null) {
          return;
        }

        const {
          id: sourceNodeId,
          data: { sourceHandles },
        } = sourceNode;

        if (sourceHandles == null) {
          return;
        }

        const sourceNodeHandle = sourceHandles.find(({ handleName }) => handleName === sourceNodeHandleName);

        if (sourceNodeHandle == null) {
          return;
        }

        const { handleId: sourceNodeHandleId = null } = sourceNodeHandle;

        onConnect({
          source: sourceNodeId,
          sourceHandle: sourceNodeHandleId,
          target: targetNodeId,
          targetHandle: targetNodeHandleId,
        });
      });
    },
    [targetNodeId, autoConnectConfigs, store, onConnect]
  );

  return <Context.Provider value={{ autoConnect }}>{children}</Context.Provider>;
}

export function useReactFlowAutoConnectProvider(): ContextType {
  const context = useContext(Context);

  if (context == null) {
    return {
      autoConnect: undefined,
    };
  }

  return context;
}
