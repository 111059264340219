import React, { memo, useCallback } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import {
  Box,
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import SelectItem from "../../base/SelectItem";
import BaseNode from "../../../features/reactflow/nodes/base/BaseNode";

interface FormData {
  itemName: string;
  quantity: number;
}

const ItemNameAndQuantityNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = {} },
  } = props;

  const formData = nodeData as FormData | undefined;
  const itemName = formData?.itemName;
  const quantity = formData?.quantity;

  const { register, getValues, setValue, handleSubmit } = useForm<FormData>({
    defaultValues: {
      itemName,
      quantity,
    },
    mode: "onBlur",
  });

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(
    ({ itemName, quantity }: FormData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.itemName = itemName;
        nodeData.quantity = Number(quantity) || 0;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow]
  );

  return (
    <BaseNode {...props}>
      <Box p={2} borderWidth={1}>
        <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
          <SelectItem value={getValues("itemName")} setValue={(value) => setValue("itemName", value)} color={color} />
          <FormControl>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Quantity
              </Text>
            </FormLabel>
            <NumberInput isRequired step={1} defaultValue={1} min={1}>
              <NumberInputField id={"quantity"} {...register("quantity")} color={color} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </form>
      </Box>
    </BaseNode>
  );
};

export default memo(ItemNameAndQuantityNode);
