import React, { memo, useCallback, useEffect, useMemo } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType, TargetHandle } from "../../../models/nodeType";
import { Controller, useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
} from "@chakra-ui/react";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import BaseNode from "../../../features/reactflow/nodes/base/BaseNode";

type BooleanOperator = "and" | "or" | "xor" | "nand" | "nor" | "xnor" | "not";

const booleanOperators: BooleanOperator[] = ["and", "or", "xor", "nand", "nor", "xnor", "not"];

interface FormData {
  operator: BooleanOperator;
  inputCount: number;
}

const BooleanOperatorNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = {}, targetHandles = [] },
  } = props;

  const formData = nodeData as FormData | undefined;
  const operator = formData?.operator ?? "not";
  const inputCount = formData?.inputCount ?? 1;

  const { register, reset, handleSubmit, control, watch } = useForm<FormData>({
    defaultValues: useMemo(
      () => ({
        operator,
        inputCount,
      }),
      [operator, inputCount]
    ),
    mode: "onChange",
  });

  const reactFlow = useReactFlow();
  const { updateNodeTargetHandles } = useUpdateNodeHandles(nodeId);

  const handleUpdate = useCallback(
    ({ operator, inputCount }: FormData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.operator = operator;
        nodeData.inputCount = inputCount;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });

      reset({
        operator,
        inputCount,
      });
    },
    [reactFlow, nodeId]
  );

  const watchedOperator = watch("operator");
  const watchedInputCount = watch("inputCount");

  const inputCountMin = useMemo<number>(() => (watchedOperator === "not" ? 1 : 2), [watchedOperator]);
  const inputCountMax = useMemo<number>(() => (watchedOperator === "not" ? 1 : 8), [watchedOperator]);

  useEffect(() => {
    if (inputCountMin <= watchedInputCount && watchedInputCount <= inputCountMax) {
      return;
    }

    handleUpdate({ operator: watchedOperator, inputCount: Math.min(inputCountMin, inputCountMax) });
  }, [watchedOperator]);

  useEffect(() => {
    const currentInputCount = targetHandles.length;

    if (currentInputCount === watchedInputCount) {
      return;
    }

    const currentTargetHandles = targetHandles.slice();

    if (currentInputCount < watchedInputCount) {
      const handlesToInsert = watchedInputCount - currentInputCount;

      currentTargetHandles.push(
        ...[...Array(handlesToInsert)].map(() => {
          const targetHandle: TargetHandle = {
            label: "Boolean",
            handleName: "boolean",
            handleType: "target",
            handleCategory: "data",
          };

          return targetHandle;
        })
      );
    } else {
      const handlesToRemove = currentInputCount - watchedInputCount;

      currentTargetHandles.splice(-handlesToRemove);
    }

    updateNodeTargetHandles(currentTargetHandles);
  }, [watchedInputCount]);

  return (
    <BaseNode {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Boolean Operator
            </Text>
          </FormLabel>
          <Select id={"operator"} textTransform={"uppercase"} {...register("operator")}>
            {booleanOperators &&
              booleanOperators.map((operator) => (
                <option key={operator} value={operator}>
                  {operator.toUpperCase()}
                </option>
              ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Boolean Input Count
            </Text>
          </FormLabel>
          <Controller
            name={"inputCount"}
            control={control}
            render={({ field: { ref, value, onChange, onBlur, name } }) => (
              <NumberInput
                value={value}
                defaultValue={inputCountMin}
                name={name}
                step={1}
                min={inputCountMin}
                max={inputCountMax}
                ref={ref}
                onChange={(value) => onChange(Number(value))}
                onBlur={onBlur}
              >
                <NumberInputField color={color} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          />
        </FormControl>
      </form>
    </BaseNode>
  );
};

export default memo(BooleanOperatorNode);
