import { memo } from "react";
import { Box } from "@chakra-ui/react";
import { useNodeTypesProvider } from "../../../../context/reactflow/NodeTypesProvider";

export interface ExperimentalNodeMarkerProps {
  nodeName: string;
}

function ExperimentalNodeMarker({ nodeName }: ExperimentalNodeMarkerProps) {
  const { getNodeType } = useNodeTypesProvider();

  const nodeType = getNodeType(nodeName);

  if (nodeType == null) {
    return null;
  }

  const { isReady } = nodeType;

  if (isReady) {
    return null;
  }

  const backgroundImage =
    "repeating-linear-gradient(-45deg, transparent, transparent 10px, #F6E05E 10px, #F6E05E 20px)";

  return <Box position={"absolute"} left={0} right={0} top={0} height={2} backgroundImage={backgroundImage} />;
}

export default memo(ExperimentalNodeMarker);
