import React, { memo, useCallback } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { Box } from "@chakra-ui/react";
import SelectNpc from "../../base/SelectNpc";
import BaseNode from "../../../features/reactflow/nodes/base/BaseNode";

interface FormData {
  npcId: string;
}

const NpcNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = {} },
  } = props;

  const formData = nodeData as FormData | undefined;
  const npcId = formData?.npcId;

  const { getValues, setValue, handleSubmit } = useForm<FormData>({
    defaultValues: {
      npcId,
    },
    mode: "onBlur",
  });

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(
    ({ npcId }: FormData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.npcId = npcId;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow]
  );

  return (
    <BaseNode {...props}>
      <Box p={2}>
        <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
          <SelectNpc value={getValues("npcId")} setValue={(value) => setValue("npcId", value)} color={color} />
        </form>
      </Box>
    </BaseNode>
  );
};

export default memo(NpcNode);
