import { SystemStyleObject } from "@chakra-ui/react";

type GetScrollbarStyle = (color?: string) => SystemStyleObject;

export const getScrollbarStyle: GetScrollbarStyle = (color?: string) => ({
  "::-webkit-scrollbar": {
    width: 3,
  },
  "::-webkit-scrollbar-track": {
    bg: "mirage.900",
  },
  "::-webkit-scrollbar-thumb": {
    bg: color,
  },
  "::-webkit-scrollbar-corner": {
    bg: color,
  },
});
