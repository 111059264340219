import { useMemo } from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { RootLayout } from "./layouts/RootLayout";
import { ReactFlowLayout } from "./layouts/ReactFlowLayout";
import useQuestLoader from "./hooks/router/useQuestLoader";
import useQuestsOverviewLoader from "./hooks/router/useQuestsOverviewLoader";
import Error from "./pages/Error";
import Quests from "./pages/Quests";
import QuestEditor from "./pages/QuestEditor";
import { QuestsLayout } from "./layouts/QuestsLayout";
import QuestsOverviewLayout from "./layouts/QuestsOverviewLayout";
import QuestsOverview from "./pages/QuestsOverview";
import QuestEditorReadOnly from "./pages/QuestEditorReadOnly";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import QuestIssues from "./pages/QuestIssues";

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: 1,
      retryDelay: 500,
    },
  },
});

export const App = () => {
  const { loader: questLoader } = useQuestLoader();
  const { loader: questsOverviewLoader } = useQuestsOverviewLoader();

  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          element: <RootLayout />,
          errorElement: <Error />,
          children: [
            {
              index: true,
              element: <Navigate replace to={"/quests"} />,
            },
            {
              path: "/quests-overview",
              element: <QuestsOverviewLayout />,
              loader: questsOverviewLoader,
              children: [
                {
                  index: true,
                  element: <QuestsOverview />,
                },
              ],
            },
            {
              path: "/quest-issues",
              children: [
                {
                  index: true,
                  element: <QuestIssues />,
                },
              ],
            },
            {
              path: "/quests",
              element: <QuestsLayout />,
              children: [
                {
                  index: true,
                  element: <Quests />,
                },
              ],
            },
            {
              path: "/quests/:id",
              element: <ReactFlowLayout />,
              loader: questLoader,
              children: [
                {
                  index: true,
                  element: <QuestEditor />,
                  loader: questLoader,
                },
              ],
            },
            {
              path: "/quests/:id/read-only",
              element: <ReactFlowLayout />,
              loader: questLoader,
              children: [
                {
                  index: true,
                  element: <QuestEditorReadOnly />,
                  loader: questLoader,
                },
              ],
            },
          ],
        },
      ]),
    [questLoader]
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools buttonPosition={"bottom-left"} />

      <RouterProvider router={router} />
    </QueryClientProvider>
  );
};
