import { Box, BoxProps } from "@chakra-ui/react";
import { memo } from "react";

function BaseNodeFooter(props: BoxProps) {
  const { color } = props;

  return <Box borderBottomWidth={1} borderBottomColor={color} {...props} />;
}

export default memo(BaseNodeFooter);
