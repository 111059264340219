import { useMemo } from "react";
import { useNodeId, useStore } from "reactflow";
import { useConnectionProvider } from "../../../../../context/ConnectionContext";
import useNodeHandlesValidation from "../../../../../hooks/useNodeHandlesValidation";

export default function useShowConnectable(handleId: string) {
  const { getValidConnectionHandles } = useNodeHandlesValidation();

  const nodeId = useNodeId();

  const { connectionHandleId, connectionHandleType } = useStore(({ connectionHandleId, connectionHandleType }) => ({
    connectionHandleId,
    connectionHandleType,
  }));

  const { selectedHandleId, selectedHandleType } = useConnectionProvider();

  const initiatingConnectionHandleId = connectionHandleId || selectedHandleId || null;
  const initiatingConnectionHandleType = connectionHandleId ? connectionHandleType : selectedHandleType || null;

  const validConnectionHandles = useMemo(
    () => getValidConnectionHandles(nodeId, initiatingConnectionHandleId, initiatingConnectionHandleType),
    [getValidConnectionHandles, nodeId, initiatingConnectionHandleId, initiatingConnectionHandleType]
  );

  const showConnectable = useMemo(
    () => validConnectionHandles.map(({ handleId }) => handleId).includes(handleId),
    [validConnectionHandles, handleId]
  );

  return showConnectable;
}
