import { Checkbox, CheckboxProps, FormControl, FormLabel } from "@chakra-ui/react";
import { ForwardedRef, forwardRef, memo } from "react";

interface FormCheckboxProps extends CheckboxProps {
  disabled?: boolean;
}

const FormCheckbox = forwardRef(({ children, ...props }: FormCheckboxProps, ref: ForwardedRef<HTMLInputElement>) => {
  return (
    <FormControl>
      <FormLabel>{children}</FormLabel>

      <Checkbox ref={ref} {...props} />
    </FormControl>
  );
});

export default memo(FormCheckbox);
