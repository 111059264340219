import React, { memo, PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { NodeProps, useReactFlow, useUpdateNodeInternals } from "reactflow";
import { NodeType, SourceHandle, TargetHandle } from "../../../models/nodeType";
import BaseNode from "../../../features/reactflow/nodes/base/BaseNode";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Tag,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import useNodeTypeNameLookup from "../../../hooks/useNodeTypeNameLookup";
import { Form } from "react-router-dom";
import QuestPointerContainer from "../../quests/QuestPointerContainer";
import {
  ReactFlowAutoConnectConfig,
  ReactFlowAutoConnectProvider,
} from "../../../context/reactflow/ReactFlowAutoConnectProvider";

interface NodeData {
  isDonePortEnabled: boolean;
  flowTargetCount: number;
  delayInSeconds: number;
}

interface FlowNodeConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  nodeId: string;
  nodeData: NodeData;
  sourceHandles: SourceHandle[];
  targetHandles: TargetHandle[];
  color?: string;
}

export const FlowNodeConfigurationModal: React.FC<FlowNodeConfigurationModalProps> = ({
  isOpen,
  onClose,
  nodeId,
  nodeData,
  sourceHandles,
  targetHandles,
  color,
}) => {
  const isDonePortEnabled = nodeData?.isDonePortEnabled ?? false;
  const delayInSeconds = nodeData?.delayInSeconds ?? 0;

  const { register, reset, handleSubmit } = useForm<NodeData>({
    defaultValues: {
      isDonePortEnabled,
      delayInSeconds,
    },
    mode: "onBlur",
  });

  const reactFlow = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const { updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);

  const handleUpdate = useCallback(
    ({ isDonePortEnabled, delayInSeconds }: NodeData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as NodeData) ?? {};
        nodeData.isDonePortEnabled = isDonePortEnabled;
        nodeData.delayInSeconds = delayInSeconds;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });

      onClose();
    },
    [onClose, reactFlow, nodeId]
  );

  useEffect(() => {
    reset({
      isDonePortEnabled,
      delayInSeconds,
    });
  }, [isDonePortEnabled, delayInSeconds]);

  const handleCancel = useCallback(() => {
    reset({
      isDonePortEnabled,
      delayInSeconds,
    });

    onClose();
  }, [onClose, reset, isDonePortEnabled, delayInSeconds]);

  useEffect(() => {
    if (isDonePortEnabled) {
      if (sourceHandles.find(({ handleName }) => handleName === "boolean") == null) {
        updateNodeSourceHandles([
          ...sourceHandles,
          {
            label: "DONE",
            handleName: "boolean",
            handleType: "source",
            handleCategory: "data",
          },
        ]);
      }
    } else {
      updateNodeSourceHandles(sourceHandles.filter(({ handleName }) => handleName !== "boolean"));
    }

    updateNodeInternals(nodeId);
  }, [isDonePortEnabled]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={"theme.dark.background"} borderColor={color} borderRadius={0} borderWidth={1}>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <ModalHeader>
            <Text color={color}>Configuration</Text>
          </ModalHeader>

          <ModalBody>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Delay in Seconds
                </Text>
              </FormLabel>
              <NumberInput>
                <NumberInputField
                  id={"delayInSeconds"}
                  {...register("delayInSeconds", { min: 0, valueAsNumber: true })}
                  color={color}
                />
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Enable DONE Output Port
                </Text>
              </FormLabel>
              <Checkbox id={"isDonePortEnabled"} {...register("isDonePortEnabled")} color={color} />
            </FormControl>
          </ModalBody>

          <ModalFooter gap={1}>
            <Button onClick={handleCancel} color={"white"} variant={"outline"}>
              Cancel
            </Button>
            <Button color={color} type={"submit"} variant={"outline"}>
              Update
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const FlowNode: React.FC<NodeProps<NodeType>> = (props) => {
  return <FlowNodeWithChildren {...props} />;
};

export const FlowNodeWithChildren: React.FC<NodeProps<NodeType> & PropsWithChildren> = ({ children, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    id: nodeId,
    type,
    data: { color, nodeData = {}, sourceHandles = [], targetHandles = [], nodeName },
  } = props;

  const flowTargetCount = nodeData?.flowTargetCount ?? 1;

  const { control, reset, watch, handleSubmit } = useForm<NodeData>({
    defaultValues: useMemo(
      () => ({
        flowTargetCount,
      }),
      [flowTargetCount]
    ),
    mode: "onChange",
  });

  const reactFlow = useReactFlow();
  const { updateNodeTargetHandles } = useUpdateNodeHandles(nodeId);

  const handleUpdate = useCallback(
    ({ flowTargetCount }: NodeData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as NodeData) ?? {};
        nodeData.flowTargetCount = flowTargetCount;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow, nodeId]
  );

  useEffect(() => {
    reset({
      flowTargetCount,
    });
  }, [flowTargetCount]);

  const watchedFlowTargetCount = watch("flowTargetCount");

  useEffect(() => {
    const currentFlowTargetCount = targetHandles.filter(({ handleName }) => handleName === "in").length;

    if (currentFlowTargetCount === watchedFlowTargetCount) {
      return;
    }

    const targetHandlesExcludingIn = targetHandles.filter(({ handleName }) => handleName !== "in");

    const targetHandlesIncludingIn = targetHandles.filter(({ handleName }) => handleName === "in");

    if (currentFlowTargetCount < watchedFlowTargetCount) {
      const handlesToInsert = watchedFlowTargetCount - currentFlowTargetCount;

      targetHandlesIncludingIn.push(
        ...[...Array(handlesToInsert)].map(() => {
          const targetHandle: TargetHandle = {
            label: "IN",
            handleName: "in",
            handleType: "target",
            handleCategory: "flow",
          };

          return targetHandle;
        })
      );
    } else {
      const handlesToRemove = currentFlowTargetCount - watchedFlowTargetCount;

      targetHandlesIncludingIn.splice(-handlesToRemove);
    }

    updateNodeTargetHandles([...targetHandlesIncludingIn, ...targetHandlesExcludingIn]);
  }, [watchedFlowTargetCount]);

  const delayInSeconds = (nodeData as NodeData)?.delayInSeconds ?? 0;

  const { setNodes } = useReactFlow();
  const { getLatestType } = useNodeTypeNameLookup();

  useEffect(() => {
    const latestType = getLatestType(undefined, nodeName, type);

    if (latestType === type) {
      return;
    }

    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id !== nodeId) {
          return node;
        }

        return {
          ...node,
          type: latestType,
        };
      })
    );
  }, [setNodes, getLatestType, type, nodeName, nodeId]);

  const autoConnectConfigs: ReactFlowAutoConnectConfig[] = useMemo(
    () => [
      {
        sourceNodeName: "start_with_npc_at_location",
        sourceNodeHandleName: "npc",
      },
      {
        sourceNodeName: "start_with_npc_at_location",
        sourceNodeHandleName: "location",
      },
    ],
    []
  );

  return (
    <ReactFlowAutoConnectProvider nodeId={nodeId} autoConnectConfigs={autoConnectConfigs}>
      <QuestPointerContainer mx={1} mb={1} color={color} nodeId={nodeId} />

      <BaseNode {...props}>
        <Stack p={2}>
          {!!delayInSeconds && (
            <VStack p={2} alignItems={"flex-start"}>
              <Tag>
                {delayInSeconds} second{delayInSeconds === 1 ? "" : "s"} delay
              </Tag>
            </VStack>
          )}

          {children && children}

          <Form
            onSubmit={handleSubmit(handleUpdate)}
            onBlur={handleSubmit(handleUpdate)}
            onChange={handleSubmit(handleUpdate)}
          >
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  IN Port Count
                </Text>
              </FormLabel>
              <Controller
                name={"flowTargetCount"}
                control={control}
                render={({ field: { ref, value, onChange, onBlur, name } }) => (
                  <NumberInput
                    value={value}
                    defaultValue={2}
                    name={name}
                    step={1}
                    min={0}
                    max={16}
                    ref={ref}
                    onChange={(value) => onChange(Number(value))}
                    onBlur={onBlur}
                  >
                    <NumberInputField color={color} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                )}
              />
            </FormControl>
          </Form>

          <Button onClick={onOpen} color={color} textTransform={"uppercase"} w={"100%"} variant={"outline"}>
            Configure Node
          </Button>

          <FlowNodeConfigurationModal
            isOpen={isOpen}
            onClose={onClose}
            nodeId={nodeId}
            nodeData={nodeData as NodeData}
            sourceHandles={sourceHandles}
            targetHandles={targetHandles}
            color={color}
          />
        </Stack>
      </BaseNode>
    </ReactFlowAutoConnectProvider>
  );
};

export default memo(FlowNode);
